import { HttpClient } from '@angular/common/http';
import { Component, OnInit } from '@angular/core';
import { Profile } from 'src/app/models/profile';
import { LocalStorageTools } from 'src/app/services/local-storage-tools';
import { ProfileService } from 'src/app/services/profile.service';
import { SpinnerOverlayService } from '../spinner/spinner-overlay/spinner-overlay.service';

const GRAPH_ENDPOINT = 'https://graph.microsoft.com/v1.0/me/photo/$value'; // Prod graph endpoint. Uncomment to use.

@Component({
  selector: 'app-profile-view',
  templateUrl: './profile-view.component.html',
  styleUrls: ['./profile-view.component.scss']
})
export class ProfileViewComponent implements OnInit {
  public profile: Profile;
  public profilePictureUrl: string = "";

  private spinnerOverlayShown: boolean = false;

  constructor(
    private http: HttpClient,
    private spinnerOverlay: SpinnerOverlayService,
    private profileService: ProfileService) {
    this.profile = new Profile();

    const storedProfilePicture = LocalStorageTools.getProfilePicture()
    if (!!storedProfilePicture && this.profilePictureUrl != storedProfilePicture)
      this.profilePictureUrl = storedProfilePicture;
  }

  ngOnInit(): void {
    let localStorageData = LocalStorageTools.getProfile();

    if (!!localStorageData) {
      this.profile = localStorageData;
      this.spinnerOverlayShown = true;
    }

    if (!localStorage.getItem("DemoLogin")) {
      this.profileService.getProfile()
        .subscribe(
          this.storeProfile.bind(this),
          this.dataLoadError.bind(this));

      if (!localStorageData) {
        this.spinnerOverlay.show("Lade Daten, bitte warten");
      }
    } else {
      this.profileService.getDemoProfile()
        .subscribe(_ => {
          this.profile = _;
          this.profilePictureUrl = _.profilePicture || "";
          LocalStorageTools.setProfile(this.profile);
        },
          this.dataLoadError.bind(this));
    }
  }

  private storeProfile(result: any): void {
    this.profile = result;
    LocalStorageTools.setProfile(this.profile);

    this.http.get(GRAPH_ENDPOINT, {
      responseType: 'blob'
    }).subscribe(profilePicture => {
      var reader = new FileReader();
      reader.readAsDataURL(profilePicture);
      reader.onloadend = () => {
        const picture = reader.result?.toString();
        if (!!picture) {
          if (this.profilePictureUrl != picture) {
            this.profilePictureUrl = picture;
            LocalStorageTools.setProfilePicture(picture);
          }
        }
        LocalStorageTools.setProfile(this.profile);
      }
    })

    if (this.spinnerOverlayShown) {
      this.spinnerOverlay.hide();
      this.spinnerOverlayShown = false;
    }
  }

  private dataLoadError(err: any): void {
    console.dir(err);

    if (this.spinnerOverlayShown) {
      this.spinnerOverlay.hide();
      this.spinnerOverlayShown = false;
    }
  }
}