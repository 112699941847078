<div class="container">
  <div class="row justify-content-center">
    <div id="col-auto loader">
      <!--<div class="dot"></div>
      <div class="dot"></div>
      <div class="dot"></div>
      <div class="dot"></div>
      <div class="dot"></div>
      <div class="dot"></div>
      <div class="dot"></div>
      <div class="dot"></div>
      <div class="loading"></div>-->
      <img src="assets/loader.svg">
    </div>
  </div>
  <div class="row justify-content-center">
    <p class="col-auto spinner-message"
       *ngIf="message && message !== ''">
      {{message}}
    </p>
  </div>
</div>
