import { Profile } from "../models/profile";

export class LocalStorageTools {
    private static readonly PROFILE_ACCESS_KEY = "Profile";
    private static readonly PROFILE_PICTURE_KEY = "ProfilePicture";
    private static readonly DEMO_LOGIN_KEY = "DemoLogin";

    public static getProfile(): Profile {
        const localStorageString = localStorage.getItem(LocalStorageTools.PROFILE_ACCESS_KEY);

        if (!!localStorageString) {
            const localStorageData = JSON.parse(localStorageString) as Profile;

            return localStorageData;
        }

        return new Profile();
    }

    public static setProfile(data: Profile): void {
        localStorage.setItem(LocalStorageTools.PROFILE_ACCESS_KEY, JSON.stringify(data));
    }

    public static setProfilePicture(picture: string): void {
        localStorage.setItem(LocalStorageTools.PROFILE_PICTURE_KEY, picture);
    }

    public static getProfilePicture(): string | null {
        const localStoragePicture = localStorage.getItem(LocalStorageTools.PROFILE_PICTURE_KEY);

        return localStoragePicture;
    }

    public static clearLocalStorage(): void {
        localStorage.removeItem(this.PROFILE_ACCESS_KEY);
        localStorage.removeItem(this.PROFILE_PICTURE_KEY);
        localStorage.removeItem(this.DEMO_LOGIN_KEY);
    }
}
