import { HttpClient } from '@angular/common/http';
import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';

@Component({
  selector: 'app-profile-selection',
  templateUrl: './profile-selection.component.html',
  styleUrls: ['./profile-selection.component.scss']
})
export class ProfileSelectionComponent implements OnInit {
  public profileNames: string[] = [];
  constructor(
    private http: HttpClient,
    private router: Router) { }

  ngOnInit(): void {

  }

  onPersonSelected(evt: any): void {
    console.dir(evt.value);


  }
}
