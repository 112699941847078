import { Injectable } from '@angular/core';
import { OAuth2AuthenticateOptions, OAuth2Client, OAuth2RefreshTokenOptions } from '@byteowls/capacitor-oauth2';
import { SecureStoragePlugin } from 'capacitor-secure-storage-plugin';
import { from, Observable, of, Subject } from 'rxjs';
import { catchError, map } from 'rxjs/operators';


const GraphAccessTokenStorageKey = "GraphAccessToken"
const FipaApiAcessStorageKey = "FipaApiAccessToken"
const RefreshTokenStorageKey = "RefreshToken"

@Injectable({
  providedIn: 'root'
})
export class NativeAuthServiceService {

  private loggedInStateSubject$ = new Subject<boolean>();

  public loggedInState$ = this.loggedInStateSubject$.asObservable();


  public getFipaOptions(): OAuth2AuthenticateOptions {
    return {
      // Test Schweiz
      // appId: "ce8520d0-74f1-4484-aa5a-378bc0046f8e",
      // authorizationBaseUrl: "https://login.microsoftonline.com/e56c1e31-7f92-4171-93e3-362e8b7bfde3/oauth2/v2.0/authorize",
      // accessTokenEndpoint: `https://login.microsoftonline.com/e56c1e31-7f92-4171-93e3-362e8b7bfde3/oauth2/v2.0/token`,

      // Test Schweiz 2
      // appId: "96e892e6-612b-4bc8-9457-21a83eeeafcd",
      // authorizationBaseUrl: "https://login.microsoftonline.com/e56c1e31-7f92-4171-93e3-362e8b7bfde3/oauth2/v2.0/authorize",
      // accessTokenEndpoint: `https://login.microsoftonline.com/e56c1e31-7f92-4171-93e3-362e8b7bfde3/oauth2/v2.0/token`,

      // Live Config: 
      appId: "758c4011-4feb-42ff-88ad-2420c3bb9f9b",
      authorizationBaseUrl: `https://login.microsoftonline.com/cae7d061-08f3-40dd-80c3-3c0b8889224a/oauth2/v2.0/authorize`,
      accessTokenEndpoint: `https://login.microsoftonline.com/cae7d061-08f3-40dd-80c3-3c0b8889224a/oauth2/v2.0/token`,

      scope: "offline_access api://758c4011-4feb-42ff-88ad-2420c3bb9f9b/verify",
      responseType: "code",
      pkceEnabled: true,
      logsEnabled: true,
      android: {
        pkceEnabled: true,
        responseType: "code",

        redirectUrl: "msauth://de.omexom.now.sipa/xloJCd0m%2FWHj0eRRsG5L%2BmSI1z4%3D"
      },
      ios: {
        responseType: "code",

        redirectUrl: "msauth.de.omexom.now.sipa://auth"
      }
    };
  }

  public getAzureOptions(): OAuth2AuthenticateOptions {
    return {
      // Test Schweiz
      // appId: "ce8520d0-74f1-4484-aa5a-378bc0046f8e",
      // authorizationBaseUrl: "https://login.microsoftonline.com/e56c1e31-7f92-4171-93e3-362e8b7bfde3/oauth2/v2.0/authorize",
      // accessTokenEndpoint: `https://login.microsoftonline.com/e56c1e31-7f92-4171-93e3-362e8b7bfde3/oauth2/v2.0/token`,

      // Test Schweiz 2
      // appId: "96e892e6-612b-4bc8-9457-21a83eeeafcd",
      // authorizationBaseUrl: "https://login.microsoftonline.com/e56c1e31-7f92-4171-93e3-362e8b7bfde3/oauth2/v2.0/authorize",
      // accessTokenEndpoint: `https://login.microsoftonline.com/e56c1e31-7f92-4171-93e3-362e8b7bfde3/oauth2/v2.0/token`,

      // Live Config: 
      appId: "758c4011-4feb-42ff-88ad-2420c3bb9f9b",
      authorizationBaseUrl: `https://login.microsoftonline.com/cae7d061-08f3-40dd-80c3-3c0b8889224a/oauth2/v2.0/authorize`,
      scope: "offline_access openid user.read",
      responseType: "code",
      pkceEnabled: true,
      logsEnabled: true,
      android: {
        pkceEnabled: true,
        responseType: "code",

        redirectUrl: "msauth://de.omexom.now.sipa/xloJCd0m%2FWHj0eRRsG5L%2BmSI1z4%3D"
      },
      ios: {
        responseType: "code",

        redirectUrl: "msauth.de.omexom.now.sipa://auth"
      }
    };
  }

  public getAzureRefreshOptionsFipa(refreshToken: any): OAuth2RefreshTokenOptions {
    return {
      // Test Schweiz:
      // appId: "ce8520d0-74f1-4484-aa5a-378bc0046f8e",
      // scope: "api://ce8520d0-74f1-4484-aa5a-378bc0046f8e/verify",
      // accessTokenEndpoint: `https://login.microsoftonline.com/e56c1e31-7f92-4171-93e3-362e8b7bfde3/oauth2/v2.0/token`,

      // Test Schweiz 2:
      // appId: "96e892e6-612b-4bc8-9457-21a83eeeafcd",
      // scope: "api://96e892e6-612b-4bc8-9457-21a83eeeafcd/fipa",
      // accessTokenEndpoint: `https://login.microsoftonline.com/e56c1e31-7f92-4171-93e3-362e8b7bfde3/oauth2/v2.0/token`,

      // Live Config: 

      appId: "758c4011-4feb-42ff-88ad-2420c3bb9f9b",
      scope: "api://758c4011-4feb-42ff-88ad-2420c3bb9f9b/verify",
      accessTokenEndpoint: `https://login.microsoftonline.com/cae7d061-08f3-40dd-80c3-3c0b8889224a/oauth2/v2.0/token`,
      refreshToken: refreshToken
    };
  }

  public getAzureRefreshOptionsGraph(refreshToken: any): OAuth2RefreshTokenOptions {
    return {
      // Test Schweiz:
      // appId: "ce8520d0-74f1-4484-aa5a-378bc0046f8e",
      // scope: "user.read", // See Azure Portal -> API permission
      // accessTokenEndpoint: `https://login.microsoftonline.com/e56c1e31-7f92-4171-93e3-362e8b7bfde3/oauth2/v2.0/token`,
      appId: "758c4011-4feb-42ff-88ad-2420c3bb9f9b",
      scope: "user.read",
      accessTokenEndpoint: `https://login.microsoftonline.com/cae7d061-08f3-40dd-80c3-3c0b8889224a/oauth2/v2.0/token`,
      refreshToken: refreshToken
    };
  }

  public refreshToken(refreshTokenOptions: OAuth2RefreshTokenOptions): Observable<any> {
    return from(OAuth2Client.refreshToken(refreshTokenOptions));
  }

  public storeGraphAccessToken(token: string): void {
    SecureStoragePlugin.set({ key: GraphAccessTokenStorageKey, value: token });
  }

  public storeFipaApiAccessToken(token: string): void {
    SecureStoragePlugin.set({ key: FipaApiAcessStorageKey, value: token });
  }

  public storeRefreshToken(token: string): void {
    SecureStoragePlugin.set({ key: RefreshTokenStorageKey, value: token });
  }

  public clearTokens(): void {
    SecureStoragePlugin.clear();
  }

  public getRefreshToken(): Observable<string | null> {
    return from(SecureStoragePlugin.get({ key: RefreshTokenStorageKey })).pipe(
      map(_ => _.value),
      catchError(_ => of(null))
    );
  }

  public getGraphAccessToken(): Observable<string | null> {
    return from(SecureStoragePlugin.get({ key: GraphAccessTokenStorageKey })).pipe(
      map(_ => _.value),
      catchError(_ => of(null))
    );
  }

  public getFipaApiAccessToken(): Observable<string | null> {
    return from(SecureStoragePlugin.get({ key: FipaApiAcessStorageKey })).pipe(
      map(_ => _.value),
      catchError(_ => of(null))
    );
  }

  public changeLoggedInState(state: boolean): void {
    this.loggedInStateSubject$.next(state);
  }
}
