import { HttpClient } from '@angular/common/http';
import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { QualificationStatus } from 'src/app/enums/qualification-status.enum';
import { QualificationType } from 'src/app/enums/qualification-type';
import { QualificationDetail } from 'src/app/models/qualification-detail';
import { LocalStorageTools } from 'src/app/services/local-storage-tools';

@Component({
  selector: 'app-qualification-detail-view',
  templateUrl: './qualification-detail-view.component.html',
  styleUrls: ['./qualification-detail-view.component.scss']
})
export class QualificationDetailViewComponent implements OnInit {
  public qualificationDetail: QualificationDetail;
  public qualificationStatus = QualificationStatus;
  public qualificationType = QualificationType;
  public Object = Object;
  public qualificationTypeString = "";

  public qualificatoinTypeMapping = {
    "DrivingLicense": "Führerschein",
    "Workshop": "Unterweisung/Lehrgang",
    "MedicalResearch": "Medizinische Untersuchung",
    "Other": "Andere",
  }

  constructor(
    private http: HttpClient,
    private route: ActivatedRoute,
    private router: Router
  ) {
    this.qualificationDetail = new QualificationDetail();
  }

  ngOnInit(): void {
    let qualificationDetailId = this.route.snapshot.paramMap.get("qualificationDetailId");

    this.setupQualificationDetailFromId(qualificationDetailId as string);
  }

  public showQualifications(): void {
    this.router.navigate(['qualification']);
  }

  public goBack() {
    this.showQualifications();
  }

  private setupQualificationDetailFromId(qualificationDetailId: string): void {
    const profile = LocalStorageTools.getProfile();
    let qualificationDetail = profile.qualifications.find(_ =>
      _.qualificationId === parseInt(qualificationDetailId));

    if (!!qualificationDetail) {
      this.qualificationDetail = qualificationDetail;
    }

    this.setupQualificationDetail();
  }

  private setupQualificationDetail(): void {
    if (Object.values(this.qualificationStatus).indexOf(this.qualificationDetail.status) === -1) {
      this.qualificationDetail.status =
        this.getStatusFromOrdinal(this.qualificationDetail.status as any as number);
    }

    this.setQualificationTypeString(
      this.qualificationDetail.qualificationType as any as number);
  }

  private getStatusFromOrdinal(statusOrdinal: number): QualificationStatus {
    let statusValues = Object.values(this.qualificationStatus);
    let status = statusValues[statusOrdinal] as QualificationStatus;

    return status;
  }

  private setQualificationTypeString(qualificationTypeOrdianl: number): void {
    // TODO: Find a better way to handle this enum as soon there is budget for it.
    this.qualificationTypeString =
      qualificationTypeOrdianl === 0 ? "Führerschein" :
        qualificationTypeOrdianl === 1 ? "Unterweisung" :
          qualificationTypeOrdianl === 2 ? "Arbeitsmedizinischer Nachweis" :
            "Sonstiges"
  }
}