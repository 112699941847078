import { QualificationStatus } from "../enums/qualification-status.enum";
import { QualificationType } from "../enums/qualification-type";

export class QualificationDetail {
    public qualificationId: number = 0;
    public title: string = "";
    public status: QualificationStatus = QualificationStatus.Invalid;
    public validFromDate: Date | null = null;
    public validTillDate: Date | null = null;
    public qualificationType: QualificationType = QualificationType.Other;
}