<mat-list>
  <div mat-subheader>{{title}}</div>
  <mat-card *ngFor="let item of items">
    <mat-list-item
                   (click)="handleQualificationEntryClick(item)"
                   id="{{item.qualificationId}}">
      <div>{{item.title}}</div>
      <i class="fas fa-info-circle"></i>
    </mat-list-item>
  </mat-card>
</mat-list>