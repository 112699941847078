import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { ImprintComponent } from './views/imprint/imprint.component';
import { LoginViewComponent } from './views/login-view/login-view.component';
import { ProfileSelectionComponent } from './views/profile-selection/profile-selection.component';
import { ProfileViewComponent } from './views/profile-view/profile-view.component';
import { QualificationDetailViewComponent } from './views/qualification-detail-view/qualification-detail-view.component';
import { QualificationViewComponent } from './views/qualification-view/qualification-view.component';

const routes: Routes = [
  { path: 'profile', component: ProfileViewComponent },
  { path: 'profileselection', component: ProfileSelectionComponent, },
  { path: 'login', component: LoginViewComponent, },
  { path: 'qualification', component: QualificationViewComponent, },
  { path: 'qualification-detail/:qualificationDetailId', component: QualificationDetailViewComponent, },
  { path: 'imprint', component: ImprintComponent, },
  { path: '', redirectTo: 'login', pathMatch: 'full', },
  { path: 'login-failed', redirectTo: 'login', pathMatch: 'full', },
];

@NgModule({
  declarations: [],
  imports: [RouterModule.forRoot(routes, { initialNavigation: 'enabled' })],
  exports: [RouterModule],
})
export class AppRoutingModule {
}
