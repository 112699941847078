<mat-toolbar color="primary" class="header mat-elevation-z4 ">
  <mat-toolbar-row>
    <mat-icon *ngIf="activeLink?.canNavigateBack" class="back-icon" fontSet="fa" fontIcon="fa-arrow-left" (click)="handleNativeBackButton()"></mat-icon>
    <span *ngIf="activeLink?.link != '/profile'">
      {{activeLink?.displayName}}
    </span>
    <img *ngIf="activeLink?.link == '/profile'" src="assets/omexom_logo.png" alt="">
  </mat-toolbar-row>
</mat-toolbar>

<div class="app-body">
  <router-outlet *ngIf="!isIframe"></router-outlet>
</div>

<div class="body">
  <nav mat-tab-nav-bar backgroundColor="primary" color="accent" *ngIf="loggedIn">
    <a mat-tab-link
       [routerLink]="links[0].link"
       routerLinkActive #rla="routerLinkActive"
       [active]="activeLink.link === links[0].link">
      <i class="fas fa-id-badge"></i>
    </a>
    <a mat-tab-link
       [routerLink]="links[1].link"
       [active]="activeLink.link === links[1].link">
      <span> {{links[1].displayName}}
      </span>
    </a>
    <a mat-tab-link
       [routerLink]="links[2].link"
       routerLinkActive #rla="routerLinkActive"
       [active]="activeLink.link === links[2].link">
      <i class="fas fa-info-circle"></i>
    </a>
  </nav>
</div>