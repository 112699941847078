import { QualificationDetail } from "./qualification-detail";

export class Profile {
    public profileId: number = 0;
    public profilePicture: string | undefined;
    public name: string = "";
    public location: string = "";
    public zip: string = "";
    public street: string = "";
    public country: string = "";
    public emergencyContact: string = "";
    public dateOfBirth: Date = new Date();
    public businessUnit: string = "";
    public businessName: string = "";
    public businessCode = "";
    public analyticalUnit: string = "";
    public personalNumber: string = "";
    public tradeAssociation: string = "";
    public tradeAssociationMemberNumber: string = "";
    public qualificationId: string = "";
    public qualifications: QualificationDetail[] = [];
    public lastDataLoad = new Date("01.01.1970");
    public companyWithAdress = "";
}