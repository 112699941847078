<div mat-subheader>Letzter Abgleich: {{lastRefresh | date:"dd.MM.yy - HH:mm"}}</div>
<app-qualification title="Führerscheine"
                   [items]="drivingLicenses">
</app-qualification>
<div class="qualification-type-container">
  <app-qualification title="Unterweisungen/Lehrgänge"
                     [items]="workshops">
  </app-qualification>
</div>
<div class="qualification-type-container">
  <app-qualification title="Arbeitsmedizinische Nachweise"
                     [items]="medicalResearches">
  </app-qualification>
</div>
<div class="qualification-type-container">
  <app-qualification title="Sonstiges"
                     [items]="others">
  </app-qualification>
</div>