import { Location } from '@angular/common';
import { Component, NgZone, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { MsalBroadcastService, MsalService } from '@azure/msal-angular';
import { EventMessage, EventType, InteractionStatus } from '@azure/msal-browser';
import { App } from '@capacitor/app';
import { Capacitor } from '@capacitor/core';
import { Observable, Subject } from 'rxjs';
import { filter, take } from 'rxjs/operators';
import { NativeAuthServiceService } from './services/native-auth-service.service';
import { SpinnerOverlayService } from './views/spinner/spinner-overlay/spinner-overlay.service';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent implements OnInit {
  private readonly _destroying$ = new Subject<void>();
  public links = [
    {
      link: "/profile",
      displayName: "Ausweis",
      display: true,
      canNavigateBack: false,
    },
    {
      link: "/qualification",
      displayName: "Qualifikationen",
      display: true,
      canNavigateBack: false,
    },
    {
      link: "/imprint",
      displayName: "Impressum",
      display: true,
      canNavigateBack: false,
    },
    {
      link: "/qualification-detail",
      displayName: "Qualifikationsdetails",
      display: true,
      canNavigateBack: true,
    },
    {
      link: "/login",
      displayName: "Login",
      display: true,
      canNavigateBack: false,
    },
  ]

  public activeLink = {
    link: "",
    displayName: "",
    display: false,
    canNavigateBack: false
  };

  public loggedIn = false

  public isIframe = window !== window.parent && !window.opener

  constructor(
    private router: Router,
    private location: Location,
    private msalBroadcastService: MsalBroadcastService,
    private msalService: MsalService,
    private spinner: SpinnerOverlayService,
    private authService: NativeAuthServiceService,
    private ngZone: NgZone) {
  }

  public ngOnInit(): void {
    App.addListener("backButton", data => this.ngZone.run(() => this.handleNativeBackButton()))

    this.router.events.subscribe((res) => {
      const url = `/${this.router.url.split("/")[1]}`
      const link = this.links.find(_ => _.link.includes(url))!
      if (!!link) {
        this.activeLink = link;
      }
    });

    if (!!localStorage.getItem("DemoLogin")) {
      this.loggedIn = true;
      this.router.navigate(["/profile"]);
      return
    }

    if (Capacitor.isNativePlatform()) {
      this.handleNativeAuth()

      this.authService.loggedInState$.pipe(take(1)).subscribe(_ => {
        this.loggedIn = _;
      })
    } else {
      this.handleMsalAuth();
    }
  }
  private handleNativeAuth(): void {
    this.authService.getRefreshToken().subscribe(_ => {
      if (!!_) {
        this.router.navigate(["/profile"]);
        this.loggedIn = true;
      }
    })
  }

  private handleMsalAuth(): void {
    this.msalService.instance.enableAccountStorageEvents();
    this.msalBroadcastService.msalSubject$
      .pipe(
        filter((msg: EventMessage) =>
          msg.eventType === EventType.ACCOUNT_ADDED || msg.eventType === EventType.ACCOUNT_REMOVED)
      ).subscribe((result: EventMessage) => {
        if (this.msalService.instance.getAllAccounts().length === 0) {
          window.location.pathname = "/"
        }
      });

    this.msalBroadcastService.inProgress$
      .pipe(
        filter((status: InteractionStatus) => status === InteractionStatus.None)
      )
      .subscribe(() => {
        let activeAccount = this.msalService.instance.getActiveAccount();

        if (!activeAccount && this.msalService.instance.getAllAccounts().length > 0) {
          let accounts = this.msalService.instance.getAllAccounts();
          this.msalService.instance.setActiveAccount(accounts[0]);
          this.router.navigateByUrl('/profile')
          this.loggedIn = true;
          this.spinner.hide();
        } else if (!!activeAccount) {
          this.router.navigateByUrl('/profile')
          this.loggedIn = true;
          this.spinner.hide();
        }
      });
  }

  public handleNativeBackButton(): void {
    if (this.activeLink.canNavigateBack) {
      this.router.navigate(['/qualification'])
    } else {
      if (this.activeLink.link === "/profile") {
        App.exitApp();
      } else {
        this.router.navigate(['/profile'])
      }
    }
  }


  public test() {
    const obs = new Observable<string>()

    obs.subscribe(_ =>  {

    })
  }
}
