import { HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { MatSnackBar } from "@angular/material/snack-bar";
import { Router } from "@angular/router";
import { Capacitor } from "@capacitor/core";
import { EMPTY, iif, Observable } from "rxjs";
import { catchError, map, mergeMap, tap } from "rxjs/operators";
import { environment } from "src/environments/environment";
import { NativeAuthServiceService } from "./services/native-auth-service.service";

@Injectable()
export class AuthInterceptor implements HttpInterceptor {
    constructor(
        private authService: NativeAuthServiceService,
        private router: Router,
        private snackBar: MatSnackBar
    ) { }

    public intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
        if (!Capacitor.isNativePlatform()) {
            return next.handle(req.clone())
        }

        if (req.url.includes("demoprofile")) {
            return next.handle(req.clone())
        }

        const url = new URL(req.url);
        const isApiUrl = url.origin == (new URL(environment.apiUrl)).origin

        let accessToken$: Observable<string | null>;
        if (isApiUrl) {
            accessToken$ = this.authService.getFipaApiAccessToken()
        } else {
            accessToken$ = this.authService.getGraphAccessToken();
        }

        return accessToken$.pipe(
            mergeMap(_ =>
                iif(() =>
                    this.isTokenExpired(_),
                    this.getNewRefreshToken(req, next, isApiUrl),
                    next.handle(req.clone({ setHeaders: { Authorization: `Bearer ${_}` } })))
            ),
            catchError(_ => {
                if (!isApiUrl && _.status === 404) {
                    return EMPTY;
                }
                this.router.navigate(["login"])
                this.snackBar.open(
                    "Ihre Anmeldung ist ungültig. Bitte melden Sie sich erneut an",
                    "Okay",
                    { duration: 5000 });

                return EMPTY;
            })
        )
    }

    private isTokenExpired(token: string | null) {
        if (!token) {
            return true;
        }

        const tokenPayload = JSON.parse(atob(token.split(".")[1]));
        const exp = tokenPayload.exp as number;

        return exp <= (Date.now() / 1000) + 30;
    }

    private getNewRefreshToken(
        req: HttpRequest<any>,
        next: HttpHandler,
        isApiUrl: boolean): Observable<HttpEvent<any>> {
        return this.authService.getRefreshToken().pipe(
            map(_ => {
                if (isApiUrl) {
                    return this.authService.getAzureRefreshOptionsFipa(_)
                } else {
                    return this.authService.getAzureRefreshOptionsGraph(_)
                }
            }),
            mergeMap(
                _ => this.authService.refreshToken(_)
            ),
            catchError(_ => {
                this.router.navigate(["Login"])
                this.snackBar.open(
                    "Ihre Anmeldung ist ungültig. Bitte melden Sie sich erneut an",
                    "Okay",
                    { duration: 5000 });

                return EMPTY;
            }),
            map(_ => _["access_token"] as string),
            tap(_ => {
                if (isApiUrl) {
                    this.authService.storeFipaApiAccessToken(_)
                } else {
                    this.authService.storeGraphAccessToken(_)
                }
            }),
            mergeMap(_ => next.handle(req.clone({ setHeaders: { Authorization: `Bearer ${_}` } })))
        );
    }
}