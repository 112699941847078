import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { QualificationType } from 'src/app/enums/qualification-type';
import { QualificationDetail } from 'src/app/models/qualification-detail';
import { LocalStorageTools } from 'src/app/services/local-storage-tools';

@Component({
  selector: 'app-qualification-view',
  templateUrl: './qualification-view.component.html',
  styleUrls: ['./qualification-view.scss']
})
export class QualificationViewComponent implements OnInit {
  public drivingLicenses: QualificationDetail[];
  public workshops: QualificationDetail[];
  public medicalResearches: QualificationDetail[];
  public others: QualificationDetail[];
  public lastRefresh: Date | undefined;

  constructor(private router: Router) {
    this.drivingLicenses = [];
    this.workshops = [];
    this.medicalResearches = [];
    this.others = [];
  }

  ngOnInit(): void {
    const profile = LocalStorageTools.getProfile();

    this.drivingLicenses = profile.qualifications.filter(_ =>
      _.qualificationType === QualificationType.DrivingLicense);
    this.workshops = profile.qualifications.filter(_ =>
      _.qualificationType === QualificationType.Workshop);
    this.medicalResearches = profile.qualifications.filter(_ =>
      _.qualificationType === QualificationType.MedicalResearch);
    this.others = profile.qualifications.filter(_ =>
      _.qualificationType === QualificationType.Other);

    this.lastRefresh = profile.lastDataLoad;
  }

  showProfile(): void {
    this.router.navigate(['profile'])
  }
}