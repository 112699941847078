<div>
  <img class="value" [src]="profilePictureUrl">
  <mat-list class="sipa-list">
    <mat-list-item role="listitem">
      <div class="flex-name">
        <p>Name:</p>
      </div>
      <div class="flex-value">
        <p>{{profile.name}}</p>
      </div>
    </mat-list-item>
    <mat-list-item>
      <div class="flex-name">
        <p>Personal-Nr.:</p>
      </div>
      <div class="flex-value">
        <p>{{profile.personalNumber}}</p>
      </div>
    </mat-list-item>
    <mat-list-item>
      <div class="flex-name">
        <p>Gesellschaft:</p>
      </div>
      <div class="flex-value">
        <p>{{profile.companyWithAdress}}</p>
      </div>
    </mat-list-item>
    <mat-list-item>
      <div class="flex-name">
        <p>Business Unit:</p>
      </div>
      <div class="flex-value">
        <p>{{profile.businessName}} ({{profile.businessCode}})</p>
      </div>
    </mat-list-item>
    <mat-list-item>
      <div class="flex-name">
        <p>Analytical Unit:</p>
      </div>
      <div class="flex-value">
        <p>{{profile.analyticalUnit}}</p>
      </div>
    </mat-list-item>
    <mat-list-item>
      <div class="flex-name">
        <p>Berufsgenossenschaft:</p>
      </div>
      <div class="flex-value">
        <p>{{profile.tradeAssociation}}</p>
      </div>
    </mat-list-item>
    <mat-list-item>
      <div class="flex-name">
        <p>BG-Mitglieds-Nr.:</p>
      </div>
      <div class="flex-value">
        <p>{{profile.tradeAssociationMemberNumber}}</p>
      </div>
    </mat-list-item>
    <br>
    <mat-list-item>
      <div class="flex-name">
        <i>Persönliche Daten:</i>
      </div>
    </mat-list-item>
    <mat-list-item>
      <div class="flex-name">
        <p>Notfallkontakt:</p>
      </div>
      <div class="flex-value">
        <p>{{profile.emergencyContact}}</p>
      </div>
    </mat-list-item>
    <mat-list-item>
      <div class="flex-name">
        <p>Wohnort:</p>
      </div>
      <div class="flex-value">
        <p>{{profile.street}}, {{profile.location}}</p>
      </div>
    </mat-list-item>
    <mat-list-item>
      <div class="flex-name">
        <p>Geburtsdatum:</p>
      </div>
      <div class="flex-value">
        <p>{{profile.dateOfBirth | date: "dd.MM.yyyy"}}</p>
      </div>
    </mat-list-item>
  </mat-list>
</div>