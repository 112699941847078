import { Component } from '@angular/core';
import { Router } from '@angular/router';
import { MsalService } from '@azure/msal-angular';
import { OAuth2Client } from '@byteowls/capacitor-oauth2';
import { Capacitor } from '@capacitor/core';
import { BehaviorSubject } from 'rxjs';
import { NativeAuthServiceService } from 'src/app/services/native-auth-service.service';
import { SpinnerOverlayService } from '../spinner/spinner-overlay/spinner-overlay.service';

@Component({
  selector: 'app-login-view',
  templateUrl: './login-view.component.html',
  styleUrls: ['./login-view.component.scss']
})
export class LoginViewComponent {
  private loggedIn = new BehaviorSubject<boolean>(false);
  public loggedIn$ = this.loggedIn.asObservable();

  constructor(
    private msalService: MsalService,
    private spinner: SpinnerOverlayService,
    private auth: NativeAuthServiceService,
    private router: Router) {

  }

  public login(): void {
    this.spinner.show();
    if (Capacitor.isNativePlatform()) {
      const FipaAuthOptions = this.auth.getFipaOptions();
      OAuth2Client.authenticate(FipaAuthOptions).then(authResult => {
        console.log("Auth Response: ")
        console.log(JSON.stringify(authResult));
        const accessTokenResponse = authResult["access_token_response"];
        console.log("AccessToken Response: ")
        console.log(JSON.stringify(accessTokenResponse));
        const fipaAccessToken = accessTokenResponse["access_token"];
        const refreshToken = accessTokenResponse["refresh_token"];

        this.auth.storeFipaApiAccessToken(fipaAccessToken);
        this.auth.storeRefreshToken(refreshToken)
        /* Use refreshToken to get an accessToken for different audience */

        const refreshAuthOptions = this.auth.getAzureRefreshOptionsGraph(refreshToken);

        OAuth2Client.refreshToken(refreshAuthOptions).then(refreshAuthResult => {
          const fipaApiAccessToken = refreshAuthResult["access_token"];
          this.auth.storeGraphAccessToken(fipaApiAccessToken);
          this.auth.changeLoggedInState(true);
          this.router.navigate(["profile"]);
          this.spinner.hide();
        })
      }).catch(error => {
        console.log(error)
      });
    } else {
      this.msalService.loginRedirect().subscribe();
    }
  }
}
