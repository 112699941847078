import { Component } from '@angular/core';
import { Router } from '@angular/router';
import { MsalService } from '@azure/msal-angular';
import { Capacitor } from '@capacitor/core';
import { Observable, of } from 'rxjs';
import { map } from 'rxjs/operators';
import { LocalStorageTools } from 'src/app/services/local-storage-tools';
import { NativeAuthServiceService } from 'src/app/services/native-auth-service.service';

@Component({
  selector: 'app-imprint',
  templateUrl: './imprint.component.html',
  styleUrls: ['./imprint.component.scss']
})
export class ImprintComponent {

  public loggedIn = true

  constructor(
    private authService: NativeAuthServiceService,
    private msalService: MsalService,
    private router: Router
  ) {
    let loggedIn = new Observable<boolean>();

    if (!!localStorage.getItem("DemoLogin")) {
      loggedIn = of(!!true);
    } else if (Capacitor.isNativePlatform()) {
      loggedIn = this.authService.getRefreshToken().pipe(
        map(_ => !!_))
    } else {
      let activeAccount = this.msalService.instance.getActiveAccount();
      loggedIn = of(!!activeAccount);
    }

    loggedIn.subscribe(_ => {
      this.loggedIn = _;
    })
  }

  public onMailClick(): void {
    location.href = "mailto:it.distribution@omexom.com"
  }

  public onPhoneClick(): void {
    location.href = "tel:+49282589333"
  }

  public logout(): void {
    LocalStorageTools.clearLocalStorage()
    if (Capacitor.isNativePlatform()) {
      this.authService.clearTokens();
      location.href = "/login";
    } else {
      const countActiveAccounts = this.msalService.instance.getAllAccounts().length
      if (countActiveAccounts > 0) {
        this.msalService.logout({ postLogoutRedirectUri: "/login" });
      } else {
        location.href = "/login";
      }
    }
  }

  public demoLogin(): void {
    localStorage.setItem("DemoLogin", JSON.stringify({ demoLogin: true }))
    location.href = "/profile";
  }
}
