import { Component, Input, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { QualificationDetail } from 'src/app/models/qualification-detail';

@Component({
  selector: 'app-qualification',
  templateUrl: './qualification.component.html',
  styleUrls: ['./qualification.component.scss']
})
export class QualificationComponent implements OnInit {
  @Input()
  public title: string = "";

  @Input()
  public items: QualificationDetail[] = [];

  constructor(private router: Router) { }

  ngOnInit(): void {
  }

  handleQualificationEntryClick(qualificationItem: QualificationDetail) {
    let qualificationDetailId = qualificationItem.qualificationId;

    if (qualificationDetailId === 0) return;

    this.router.navigateByUrl(`qualification-detail/${qualificationDetailId}`);
  }
}
