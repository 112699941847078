import { Overlay, OverlayRef } from '@angular/cdk/overlay';
import { ComponentPortal } from '@angular/cdk/portal';
import { Injectable } from '@angular/core';
import { SpinnerOverlayComponent } from './spinner-overlay.component';

@Injectable({
  providedIn: 'root'
})
export class SpinnerOverlayService {
  private overlayRef: OverlayRef | undefined;
  private timeoutId: number | undefined;
  private timeoutId2: number | undefined;

  // Number of successive show requests (stacking)
  // Show loader as long as at least one request is active
  private showRequestCount = 0;

  constructor(
    private overlay: Overlay
  ) {
  }

  public show(message = '', top = 0) {
    if (this.showRequestCount === 0) {
      this.timeoutId = window.setTimeout(() => {
        this.innerShow(message, top);
      }, 150);
    }
    this.showRequestCount++;
  }

  // This will only show one loader on the first time a call is made
  // Subsequent calls will add up, hide has to be called equal number of times to hide again
  // Kind of semaphore to synchronize the loading spinner
  public showLoading() {
    this.show("Lade");
  }

  // Use force = true if an error ends everyhing
  public hide(force = false) {
    if (this.showRequestCount > 0) {
      // If showLoadings were previously called decrease count
      this.showRequestCount--;
    }

    if (force) {
      this.showRequestCount = 0;
    }

    // TODO The semaphor does not work properly, fix later
    if (this.showRequestCount == 0) {
      // Count should be 0 either if show was used or all showLoadings have had their hide call

      // Clear both timeouts to stop any show from happening
      window.clearTimeout(this.timeoutId);
      window.clearTimeout(this.timeoutId2);

      if (!!this.overlayRef) {
        this.overlayRef.detach();
      }
    }
  }

  private innerShow(message = '', top: number) {
    // Uses an OverlayRef (which is a PortalHost)

    if (!this.overlayRef) {
      this.overlayRef = this.overlay.create();
    }

    // Create ComponentPortal that can be attached to a PortalHost
    const spinnerOverlayPortal = new ComponentPortal(SpinnerOverlayComponent);

    // Run in async context for triggering "tick",
    // thus avoid ExpressionChangedAfterItHasBeenCheckedError
    this.timeoutId2 = window.setTimeout(() => {
      // Attach ComponentPortal to PortalHost
      const component = (this.overlayRef as OverlayRef).attach(spinnerOverlayPortal);

      component.instance.message = message;
      component.instance.top = top;
    });
  }
}