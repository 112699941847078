<div class="container">
    <h1>Omexom Smart Technologies GmbH</h1>
    <p class="address">Horlemannplatz 1 <br>
        47589 Uedem</p>
    <button mat-flat-button
            color="primary"
            (click)="onPhoneClick()">
        <i class="fas fa-phone"></i>
        +49 (2825) / 89 333
    </button>

    <button mat-flat-button
            color="primary"
            (click)="onMailClick()">
        <i class="fas fa-envelope"></i>
        it.distribution@omexom.com
    </button>

    <div class="account-actions">
        <button *ngIf="loggedIn"
                mat-flat-button
                color="primary"
                (click)="logout()">
            Ausloggen
        </button>

        <button *ngIf="!loggedIn"
                mat-flat-button
                color="primary"
                (click)="demoLogin()">
            Demo Login
        </button>
        <button *ngIf="!loggedIn"
                mat-flat-button
                color="primary"
                routerLink="/login">
            Zurück zum Login
        </button>
    </div>

</div>