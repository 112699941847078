<mat-card>
  <h1>Login Simulation</h1>

  <mat-form-field appearance="fill">
    <mat-label>Nutzer</mat-label>
    <mat-select (selectionChange)="onPersonSelected($event)">
      <mat-option *ngFor="let current of profileNames" [value]="current">
        {{current}}
      </mat-option>
    </mat-select>
  </mat-form-field>
</mat-card>