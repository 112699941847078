import { OverlayModule } from '@angular/cdk/overlay';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { NgModule } from '@angular/core';
import { MatButtonModule } from '@angular/material/button';
import { MatCardModule } from '@angular/material/card';
import { MatDividerModule } from '@angular/material/divider';
import { MatIconModule } from '@angular/material/icon';
import { MatListModule } from '@angular/material/list';
import { MatSelectModule } from '@angular/material/select';
import { MatSnackBarModule } from '@angular/material/snack-bar';
import { MatTabsModule } from '@angular/material/tabs';
import { MatToolbarModule } from '@angular/material/toolbar';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { InAppBrowser } from '@awesome-cordova-plugins/in-app-browser/ngx';
import { MsalBroadcastService, MsalGuardConfiguration, MsalInterceptor, MsalInterceptorConfiguration, MsalModule, MsalRedirectComponent, MsalService, MSAL_GUARD_CONFIG, MSAL_INSTANCE, MSAL_INTERCEPTOR_CONFIG } from '@azure/msal-angular';
import { InteractionType, IPublicClientApplication, PublicClientApplication } from '@azure/msal-browser';
import { Capacitor } from '@capacitor/core';
import { environment } from 'src/environments/environment';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { AuthInterceptor } from './auth-interceptor';
import { ImprintComponent } from './views/imprint/imprint.component';
import { LoginViewComponent } from './views/login-view/login-view.component';
import { ProfileSelectionComponent } from './views/profile-selection/profile-selection.component';
import { ProfileViewComponent } from './views/profile-view/profile-view.component';
import { QualificationDetailViewComponent } from './views/qualification-detail-view/qualification-detail-view.component';
import { QualificationViewComponent } from './views/qualification-view/qualification-view.component';
import { QualificationComponent } from './views/qualification/qualification.component';
import { SpinnerOverlayModule } from './views/spinner/spinner-overlay/spinner-overlay.module';
import { SpinnerModule } from './views/spinner/spinner/spinner.module';
const isIE = window.navigator.userAgent.indexOf('MSIE ') > -1 || window.navigator.userAgent.indexOf('Trident/') > -1;

export function MSALInstanceFactory(): IPublicClientApplication {
  return new PublicClientApplication({
    auth: {
      /*
       * Test Schweiz
       * */

      // clientId: 'ce8520d0-74f1-4484-aa5a-378bc0046f8e',
      // authority: 'https://login.microsoftonline.com/e56c1e31-7f92-4171-93e3-362e8b7bfde3',
      // redirectUri: 'https://localhost:3000',

      /*
       * Prod
       * */

      clientId: '758c4011-4feb-42ff-88ad-2420c3bb9f9b',
      authority: 'https://login.microsoftonline.com/cae7d061-08f3-40dd-80c3-3c0b8889224a',
      redirectUri: environment.redirectUrl,
      navigateToLoginRequestUrl: true,
    },

    cache: {
      cacheLocation: 'localStorage',
      storeAuthStateInCookie: isIE, // Set to true for Internet Explorer 11
    },
  })
}

export function MSALGuardConfigFactory(): MsalGuardConfiguration {
  return {
    interactionType: InteractionType.Redirect,
    authRequest: {
      scopes: ['user.read'],
      prompt: "none",
    },
    loginFailedRoute: '/login'
  };
}

export function MSALInterceptorConfigFactory(): MsalInterceptorConfiguration {
  return {
    interactionType: InteractionType.Redirect,
    protectedResourceMap: new Map([
      [`${environment.apiUrl}/profiles/demoprofile`, null],
      [`${environment.apiUrl}/profiles`, ['api://758c4011-4feb-42ff-88ad-2420c3bb9f9b/verify']],
      ['https://graph.microsoft.com/v1.0/me/photo', ['user.read']]
    ])
  };
}

export function getInterceptorClass() {
  if (Capacitor.isNativePlatform()) {
    return AuthInterceptor
  } else {
    return MsalInterceptor
  }
}

@NgModule({
  declarations: [
    AppComponent,
    ProfileViewComponent,
    QualificationViewComponent,
    QualificationDetailViewComponent,
    QualificationComponent,
    ProfileSelectionComponent,
    LoginViewComponent,
    ImprintComponent,
  ],
  imports: [
    BrowserModule,
    HttpClientModule,
    AppRoutingModule,
    BrowserAnimationsModule,
    MatButtonModule,
    MatToolbarModule,
    MatListModule,
    MatDividerModule,
    MatTabsModule,
    MatCardModule,
    MatIconModule,
    MatSnackBarModule,
    SpinnerModule,
    SpinnerOverlayModule,
    OverlayModule,
    MatSelectModule,
    MsalModule
  ],
  providers: [
    InAppBrowser,
    {
      provide: HTTP_INTERCEPTORS,
      useClass: getInterceptorClass(),
      multi: true
    },
    {
      provide: MSAL_INSTANCE,
      useFactory: MSALInstanceFactory,
    },
    {
      provide: MSAL_INTERCEPTOR_CONFIG,
      useFactory: MSALInterceptorConfigFactory,
    },
    {
      provide: MSAL_GUARD_CONFIG,
      useFactory: MSALGuardConfigFactory
    },
    MsalService,
    MsalBroadcastService],
  bootstrap: [AppComponent, MsalRedirectComponent]
})
export class AppModule { }
