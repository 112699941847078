<mat-card>
    <mat-list class="sipa-list qualification-title">
        <mat-list-item role="listitem">
            <div class="flex-label"> {{qualificationTypeString}}:</div>
            <div class="flex-value"> {{qualificationDetail.title}}</div>
        </mat-list-item>
    </mat-list>
</mat-card>

<mat-card>
    <mat-list class="sipa-list">
        <div mat-subheader>Gültigkeit:</div>
        <mat-list-item role="listitem"
                       *ngIf="!!qualificationDetail.validFromDate">
            <div class="flex-label">Von:</div>
            <div class="flex-value">{{qualificationDetail.validFromDate | date: "dd.MM.yyy"}}</div>
        </mat-list-item>
        <mat-list-item role="listitem"
                       *ngIf="!!qualificationDetail.validTillDate">
            <div class="flex-label">Bis:</div>
            <div class="flex-value">{{qualificationDetail.validTillDate | date: "dd.MM.yyy"}}</div>
        </mat-list-item>
        <mat-list-item role="listitem">
            <div class="flex-label">Status:</div>
            <div class="flex-value"
                 [ngClass]="qualificationDetail.status == qualificationStatus.Valid ? 'valid': 'invalid'">
                {{qualificationDetail.status}}
            </div>
        </mat-list-item>
    </mat-list>
</mat-card>