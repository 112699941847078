<div>
  <mat-card>
    <div>Sie sind nicht eingeloggt, bitte loggen Sie sich mit Ihren Vinci Login Daten ein.</div>

    <button (click)="login()" mat-raised-button color="primary">Login</button>
    <button class="imprint"
            mat-flat-button
            color="primary"
            routerLink="/imprint">
      Impressum
    </button>
    <!-- <button (click)="redirectToProfile()" mat-raised-button color="primary">Profil</button> -->
  </mat-card>
</div>